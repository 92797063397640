import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"

export const Container = styled.div`
  display: grid;
  height: 100vh;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding: 24px 24px;
  justify-content: center;
  background-color: #fff;
`

export const Row = styled.section`
  display: grid;
  grid-auto-columns: minmax(auto, 1fr);
  align-items: center;
  grid-template-areas: ${props => {
    return props.imgStart ? `'col2 col1'` : `'col1 col2'`
  }};

  @media screen and (max-width: 769px) {
    grid-template-areas: ${props =>
      props.imgStart ? `'col2 col2' 'col1 col1'` : `'col1 col1' 'col2 col2'`};
  }
`

export const ColumnA = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col1;
`

export const ColumnB = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col2;
`

export const ContentWrapper = styled.div`
  padding-top: 0;
  padding-bottom: 60px;
  color: ${props => (props.lightText ? "#fafafa" : "#333")};
`

export const ImageWrapper = styled.div`
  max-width: 555px;
  height: 100%;
`

export const ImageItem = styled(GatsbyImage)`
  width: 100%;
  margin: 0 0 10px 0;
  padding-right: 0;
`

export const Heading = styled.h2`
  margin-bottom: 24px;
  line-height: 1.1;
`

export const Description = styled.p`
  max-width: 440px;
  margin-bottom: 35px;
  text-align: justify;
`
