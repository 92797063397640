import React from "react"

import {
  Container,
  ColumnA,
  ColumnB,
  Row,
  ContentWrapper,
  ImageWrapper,
  ImageItem,
  Heading,
  Description,
} from "./OurMission.styles"

const OurMission = ({
  title,
  description,
  sideImage: { pluginImage, alt },
}) => {
  return (
    <Container>
      <Row imgStart={true}>
        <ColumnA>
          <ContentWrapper>
            <Heading>{title}</Heading>
            <Description>{description}</Description>
          </ContentWrapper>
        </ColumnA>
        <ColumnB>
          <ImageWrapper>
            <ImageItem
              image={pluginImage.childImageSharp.gatsbyImageData}
              alt={alt}
            />
          </ImageWrapper>
        </ColumnB>
      </Row>
    </Container>
  )
}

export default OurMission
