import { graphql, useStaticQuery } from "gatsby"

export const useAboutPageDataQuery = () => {
  const data = useStaticQuery(graphql`
    query AboutPage {
      mdx(frontmatter: { type: { eq: "aboutPage" } }) {
        id
        frontmatter {
          heroContent {
            backgroundImage {
              alt
              pluginImage {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                    quality: 90
                    layout: FULL_WIDTH
                    formats: AUTO
                  )
                }
              }
            }
            id
            subTitle
            title
          }
          techSectionContent {
            id
            description
            title
            sideVideo {
              videoTitle
              videoURL
            }
          }
        }
      }
    }
  `)
  return data.mdx.frontmatter
}
