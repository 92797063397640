import React from "react"
import Seo from "components/seo"
import HeroSection from "components/HeroSection"
import CommonSection from "components/CommonSection"
import Video from "./Video"
import OurMission from "components/CommonSection/OurMission"
import { useAboutPageDataQuery } from "hooks/useAboutPageDataQuery"
import { useIndexPageDataQuery } from "hooks/useIndexPageDataQuery"

const Index = () => {
  const {
    heroContent,
    techSectionContent,
    techSectionContent: { sideVideo },
  } = useAboutPageDataQuery()
  const { ourMissionSectionContent } = useIndexPageDataQuery()

  return (
    <>
      <Seo title="Sobre nós" />
      <HeroSection {...heroContent} />
      <CommonSection {...techSectionContent}>
        <Video
          videoSrcURL={sideVideo.videoURL}
          videoTitle={sideVideo.videoTitle}
        />
      </CommonSection>
      <OurMission {...ourMissionSectionContent} />
    </>
  )
}

export default Index
